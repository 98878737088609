import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const ANALYTICS_LOG_EVENT_MUTATION = gql`
  mutation AnalyticsLogEvent_Shared_Mutation($input: AnalyticsLogEventInput!) {
    analyticsLogEvent(input: $input) {
      errors {
        message
      }
    }
  }
`;

interface AnalyticsLogEventError {
  message: string;
}

interface AnalyticsLogEventData {
  analyticsLogEvent: {
    errors: AnalyticsLogEventError;
  };
}

interface Variables {
  input: {
    eventName: string;
    payload: any;
  };
}

const useAnalyticsLogEventMutation = (
  options?: MutationHookOptions<AnalyticsLogEventData, Variables>
) => useMutation<AnalyticsLogEventData, Variables>(ANALYTICS_LOG_EVENT_MUTATION, options);

export type { AnalyticsLogEventData };
export { useAnalyticsLogEventMutation, ANALYTICS_LOG_EVENT_MUTATION };
